<template>
  <div ref="preloader" class="preloader">
    <div class="blobs">
      <div class="blob" />
      <div class="blob" />
      
      <svg xmlns="http://www.w3.org/2000/svg">
        <defs>
          <filter id="goo">
            <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10" />
            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo" />
            <feBlend in2="goo" in="SourceGraphic" result="mix" />
          </filter>
        </defs>
      </svg>
    </div>
  </div>
</template>

<style src="@skeleton/assets/styles/components/atomic/preloader.scss" lang="scss" />
